<template>
    <div style="max-width: 300px;margin: 0 auto;padding-top:30px">
        <el-form :model="dataForm" :rules="rules" ref="formCheck" label-width="120px" class="login-form">
            <el-form-item prop="set_id" label="商品分类名称">
                <!-- <el-select  v-model="dataForm.set_id" style="width:200px"  placeholder="请选择移动后的商品分类">
                    <el-option v-for="item in series" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select> -->
                <el-cascader :options="classify" :show-all-levels="false" :props="{value:'id',label:'name',emitPath:false}"
                    v-model="dataForm.set_id" placeholder="请选择商品分类">
                </el-cascader>
            </el-form-item>
            <div class="flex flex-justify-around" style="padding-top:30px;">
                <div><el-button @click="hide('cancel')" style="width: 80px;" type="primary" plain>取消</el-button></div>
                <div><el-button @click="submitForm()" style="width: 80px;" type="primary">提交</el-button></div>
            </div>
        </el-form>
    </div>
</template>
<script>
    import _ from 'lodash';
    import { fetchTree } from '@/util';
    import { classifyList } from '@/service/item';
    import { multiUpdateselfProduct } from '@/service/factory';
    import { multiUpdateFxProduct } from '@/service/sharer';
    export default {
        components: {
            
        },
        props: ['dataForm',],
        data() {
            return {
                rules: {
                    set_id: [
                        { required: true, message: '请选择移动后的商品分类', trigger: 'blur' }
                    ],
                },
                comp_type: null, // 公司类型 1工厂，2设计公司，销售公司
                classifyList:[],
                classify:[],
            }
        },
        watch:{
            dataForm:{
                handler() {
                },
                deep: true
            }
        },
        async created() {
            this.comp_type = this.$store.state.loginUser.comp.comp_type;
            let classify = await classifyList(this.$store.state.loginUser.comp.id);
            if (classify && classify.length>0) {
                classify = fetchTree(classify, null, { name: { dest: 'name' }, id: { dest: 'id' }, parent: 'parentId' });
                classify.forEach(e => {
                    if (e.children && e.children.length>0) {
                        this.classify.push(e);
                    }
                });
            }
            console.log(this.dataForm)
        },
        mounted() {},
        methods: {
            submitForm() {
                this.$refs['formCheck'].validate((valid) => {
                    if (valid) {
                        this.submit();
                    }
                });
            },
            submit:_.debounce(function(){
                const param = {rows: [{
                    custom_cat_no:this.dataForm.set_id,
                    id:this.dataForm.product_id,
                }]}
                if (this.comp_type==1) {
                    multiUpdateselfProduct(param).then(rst => {
                        this.hide();
                        this.$message.success("移动成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                } else if (this.comp_type==3) {
                    multiUpdateFxProduct(param).then(rst => {
                        this.hide();
                        this.$message.success("移动成功");
                    }).catch(err => {
                        this.$message.error(err.message);
                    });
                }
            },1000),
            hide(type) {
                this.$emit('hide',type);
            },
        }
    }
</script>
<style scoped>
    .list>div {
        padding: 5px 0;
    }
    
</style>