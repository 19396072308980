<template>
  <div class="zt-page-content">
    <div class="zt-block" style="margin-bottom:0"></div>

    <div>
      <div class="zt-page-content">
        <div class="zt-block" style="margin-top:0;">
          <div
            class="select_list flex"
            style="padding:20px;"
          >
            <div class="name flex-1">
              分类名称：
              <span v-for="(item,idx) in nameList" :key="idx">
                <span v-if="idx>0 && idx<nameList.length"> - </span>
                <span v-if="item">{{item}}</span>
              </span>
            </div>
            <el-button @click="add_good()" size="small" type="primary">新增商品</el-button>
            <el-button v-if="comp_type && comp_type==3" @click="add_saleGood()" size="small" type="primary">添加现有商品</el-button>
            <el-button @click="back()" size="small" type="text">返回上一级</el-button>
          </div>
        </div>
        <div class="zt-block">
          <div style="padding:10px 30px;">
            <div
              class="list"
              style="height: calc(100% - 60px);overflow-y: auto"
            >
              <el-table :data="goodsList" style="width: 100%;">
                <el-table-column prop="name" label="商品名称">
                  <template slot-scope="scope">
                    <span>{{ scope.row.name }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="cat" label="类目">
                  <template slot-scope="scope">
                    {{ scope.row.cat }}
                  </template>
                </el-table-column>

                <el-table-column prop="address" label="操作" width="180">
                  <template slot-scope="scope">
                    <el-button
                      type="text"
                      style="color:#016FFF;"
                      size="small"
                      @click="move_quota(scope.row)"
                    >
                      移动至其他分类
                    </el-button>
                    <el-button
                      type="text"
                      style="color:red;"
                      size="small"
                      @click="del_cat(scope.row)"
                    >
                      移除分类
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>

            <div style="padding:12px">
              <el-pagination
                background
                @current-change="showGoodList"
                layout="prev, pager, next"
                :current-page.sync="goodsPageConfig.page"
                :page-size="goodsPageConfig.pagesize"
                :total="goodsPageConfig.total"
              ></el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog width="645px" :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
      <move-txgl v-if="popTitle=='移动至其他分类'" :dataForm="ruleForm" @hide="hide"></move-txgl>
      <add-good v-if="popTitle=='添加现有商品'" :set_id="set_id" :list="goodsList" @hide="hide"></add-good>
    </el-dialog>
  </div>
</template>
<script>
import { listSetProduct, editOrgcatProduct } from "@/service/item";
import { getSelfList, multiUpdateselfProduct } from '@/service/factory';
import { listFxProduct, multiUpdateFxProduct } from '@/service/sharer';
import MoveTxgl from "./MoveTxgl.vue";
import AddGood from "./AddGood.vue";
export default {
  components: {
    "move-txgl": MoveTxgl,
    "add-good": AddGood
  },
  data() {
    return {
      popVisible: false,
      popTitle:"",
      ruleForm: {},
      goodsList: [],
      goodsPageConfig: {
        page: 1,
        pagesize: 15,
        total: 1
      },
      comp_type: null,
      set_id: "", //商品分类 id
      product_id: "", //商品 id
      price_type: 0, //价格类型  0：版权出售    1：分成合作 工厂账号下菜单：price_type    0：工厂款    1：合作款
      nameList: null,
      ids: [],
    };
  },
  created() {},
  mounted() {
    this.comp_type = this.$store.state.loginUser.comp.comp_type;
    if (this.$route.query.set_id) {
      this.set_id = this.$route.query.set_id;
    }
    if (this.$route.query.name) {
      this.nameList = this.$route.query.name.split(",");
    }
    if (this.$route.query?.price_type)
      this.price_type = this.$route.query.price_type;
    this.showGoodList();
  },
  methods: {
    //获取商品列表
    async showGoodList() {
      const param = {
        custom_cat_no: this.set_id,
        page: this.goodsPageConfig.page,
        pagesize: this.goodsPageConfig.pagesize
      };
      let res = null;
      if (this.comp_type==1) {
        res = await getSelfList(param);
      } else if (this.comp_type==3) {
        res = await listFxProduct(param);
      }
      if (res && res.list && res.list.length>0) {
        this.goodsList = res.list.map(item => {
          return {
            ...item,
            cat: item.cat.name
          };
        });
        this.goodsPageConfig.total = res.total;
      } else {
        this.goodsList = [];
      }
      console.log("获取商品列表", res);
    },
    //移动至其他商品分类
    move_quota(item) {
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // }
      this.ruleForm = {
        set_id: this.set_id,
        product_id: item.id,
        price_type: this.price_type
      };
      this.popTitle = "移动至其他分类";
      this.popVisible = true;
    },
    //删除分类
    del_cat(item) {
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // }
      this.$confirm(`确认从该删除该商品吗?`, "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        const param = {rows: [{
          custom_cat_no: "",
          id: item.id
        }]};
        if (this.comp_type==1) {
          multiUpdateselfProduct(param).then(res => {
            this.$message.success("删除成功!");
            this.showGoodList();
          }).catch(err => {
            this.$message.error(err.message);
          });
        } else if (this.comp_type==3) {
          multiUpdateFxProduct(param).then(res => {
            this.$message.success("删除成功!");
            this.showGoodList();
          }).catch(err => {
            this.$message.error(err.message);
          });
        }
        
      });
      console.log("del_cat", item);
    },
    //新建商品
    add_good() {
      // console.log(5555);
      // if (this.$store.state.loginUser.comp.cert_stat != 2) {
      //   this.$message.error("企业未认证");
      //   return;
      // }
      if (this.$store.state.loginUser.comp.comp_type==1 && this.price_type==0) {
        this.$router.push({
          path: "/factory/zysp/submit",
          query: { set_no: this.set_id }
        });
      } else if (this.price_type==5) {
        this.$router.push({
          path: "/agent/agentGoods/addSelfGoods",
          query: { set_no: this.set_id }
        });
      } else if (this.$store.state.loginUser.comp.comp_type==2 && this.price_type==0) {
        this.$router.push({
          path: "/desgin/sjbq/submit",
          query: { set_no: this.set_id }
        });
      } else if (this.$store.state.loginUser.comp.comp_type==2 && this.price_type==1) {
        this.$router.push({
          path: "/desgin/fchz/submit",
          query: { set_no: this.set_id }
        });
      }
    },
    add_saleGood() {
      // this.goodsList.forEach(e => {
      //   this.ids.push(e.id);
      // })
      this.popTitle = "添加现有商品";
      this.popVisible = true;
    },
    //关闭 dialog 事件 type:cancel 取消
    hide(type) {
      console.log(type);
      this.popVisible = false;
      if (type == "cancel") return;
      this.showGoodList();
    },
    //返回上一级
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style scoped>
  .select_list .name {
    line-height: 32px;
    font-size: 16px;
    color: #4D4D4D;
  }
  .select_list .name span {
    font-size: 14px;
    color: #808080;
  }
</style>
