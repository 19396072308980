<template>
    <div class="zt-page-content">
        <div class="zt-block" style="margin-bottom:0">
            <!-- <div class="tab_menu flex">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    {{item.name}}
                </div>
            </div> -->
        </div>

        <div>
            <GoodCat></GoodCat>
        </div>
    </div>
</template>
<script>
    import GoodCat from '@/components/txglCommon/GoodCat.vue';
    export default {
        components: {
            GoodCat
        },
        data() {
            return {
                
            }
        },
        created() {
            if (this.$route.query.idx) {
                this.tabIndex = this.$route.query.idx;
            }
        },
        mounted() {},
        methods: { 

        },
    }
</script>
<style scoped>
    
</style>